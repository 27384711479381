import vuetify from '@/plugins/vuetify';
import onecolor from 'onecolor';
import { createStore } from 'vuex';

const USERDATA_KEY = 'ud';
const SAVED_PALETTES_KEY = 'sp';

const store = createStore({
  state: {
    count: 0,
    headerColorHex: '',
    lightThreshold: 0.5,
    activePalette: null,
    loggedIn: false,
    cognitoInfo: null,
    errorloadingLogin: false,
    loadingLogin: false,
    userdata: null,
  },
  mutations: {
    increment (state) {
      state.count++
    },
    setActivePalette(state, palette) {
      state.activePalette = palette;
    },
    loggedIn(state, v) {
      state.loggedIn = v;
    },
    cognitoInfo(state, v) {
      state.cognitoInfo = v;
    },
    loadingLogin(state, v) {
      state.loadingLogin = v;
    },
    errorloadingLogin(state, v) {
      state.errorloadingLogin = v;
    },
    userdata(state, v) {
      state.userdata = v;
    },
  },
  actions: {
    setHeaderColorHex({ commit }, hex) {
      commit('setHeaderColorHex', hex)
    },
    setActivePalette({ commit }, palette) {
      commit('setActivePalette', palette);

      const { base, white, accent } = palette;
      const ocbase = onecolor(base.hex);
      const ocwhite = onecolor(white.hex);
      const ocaccent = onecolor(accent.hex);

      const colors = {
        background: base.hex,
        surface: base.hex,
        primary: base.hex,
        'primary-darken-1': ocbase.darken(0.1).saturation(ocbase.saturation() + 0.1).hex(),
        'primary-darken-2': ocbase.darken(0.2).saturation(ocbase.saturation() + 0.2).hex(),
        secondary: white.hex,
        'secondary-darken-1': ocwhite.darken(0.1).saturation(ocwhite.saturation() + 0.1).hex(),
        'secondary-darken-2': ocwhite.darken(0.2).saturation(ocwhite.saturation() + 0.2).hex(),
        accent: accent.hex,
        'accent-darken-1': ocaccent.darken(0.1).saturation(ocaccent.saturation() + 0.1).hex(),
        'accent-darken-2': ocaccent.darken(0.2).saturation(ocaccent.saturation() + 0.2).hex(),
        success: '#4CAF50',
      };
      vuetify.theme.themes.value.myTheme.colors = colors;
    },
    login({ commit }, userdata) {
      commit('userdata', userdata);
      commit('loggedIn', true);
      localStorage.setItem(USERDATA_KEY, JSON.stringify(userdata));
    },
    logout({ commit }) {
      commit('userdata', null);
      commit('loggedIn', false);
    },
    setLoggedOut({ commit }) {
      commit('loggedIn', false);
      commit('cognitoInfo', {});
    },
    setCognitoInfo({ commit }, newValue){
      commit('cognitoInfo', newValue)
    },
    setLoadingLogin({ commit }, newValue){
      commit('loadingLogin', newValue)
    },
    setErrorLoadingLogin({ commit }, newValue){
      commit('errorLoadingLogin', newValue)
    },
  },
  getters: {
    headerColorHex({ state }) {
      let baseColor = state.activePalette?.base?.hex;
      if(baseColor) {
        return onecolor(baseColor).hex();
      }
    }
  }
});

try {
  const ud = localStorage.getItem(USERDATA_KEY);
  if (ud) {
    const userdata = JSON.parse(ud);
    if (userdata.exp && userdata.exp * 1000 > Date.now()) {
      store.dispatch('login', userdata);
    } else {
      localStorage.removeItem(USERDATA_KEY);
    }
  }
} catch(e) {
  console.error('Error when retrieving stored userdata.');
  console.error(e);
  localStorage.removeItem(USERDATA_KEY);
}


try {
  const sp = localStorage.getItem(SAVED_PALETTES_KEY);
  if (sp) {
    const savedPalettes = JSON.parse(sp);
    if (savedPalettes?.length) {
      store.dispatch('setActivePalette', savedPalettes[0]);
    }
  }
} catch(e) {
  console.error(e);
}

export default store;
