<template>
  <v-autocomplete
    label="Select a color"
    :items="colors"
    item-title="name"
    v-model="selectedColor"
    return-object
    :hint="selectedColor.hex"
    persistent-hint
    ref="color"
  >
    <template v-slot:selection="{ item }">
    {{ item?.raw?.name }}
    </template>

    <template v-slot:append>
      <v-icon title="Copy hex value" @click="copyToClipboard">{{ copyIcon }}</v-icon>
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbarTimeout"
        color="success"
      >
        {{ snackbarText }}

        <template v-slot:actions>
          <v-btn
            variant="text"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>

    <template v-slot:item="{ item, props }">
      <v-list-item
        v-bind="props"
        :style="{ backgroundColor: item?.raw?.hex, height: '4em', color: isDarkColor(item?.raw?.hex) ? 'white' : 'black' }"
        :title="''"
      >
        <!-- <v-avatar class="float-left mr-4" :style="{ backgroundColor: item?.raw?.hex }"></v-avatar> -->
        <v-list-item-title>
          {{ item?.raw?.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item?.raw?.hex }}
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <!-- <template v-slot:item="data">
      <v-list-item>
        <v-avatar :style="{ backgroundColor: data.item.hex }"></v-avatar>
        <v-list-item-title v-html="data.item.name"></v-list-item-title>
        <v-list-item-subtitle>{{ data.item.hex }}</v-list-item-subtitle>
      </v-list-item>
    </template> -->
  </v-autocomplete>
</template>

<script setup>
import { isDarkColor } from '../lib/utils';
</script>

<script>

export default {
  name: 'ColorAutocomplete',
  props: {
    colors: Array,
    modelValue: Object,
  },
  data: () => ({
    selectedColor: null,
    copyIcon: 'mdi-content-copy',
    snackbar: false,
    snackbarTimeout: 2000,
    snackbarText: 'Copied hex value',
  }),
  emits: ['update:modelValue', 'change'],
  created() {
    // console.log(this.modelValue)
    this.selectedColor = this.modelValue;
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.selectedColor.hex);
      this.snackbar = true;
      this.copyIcon = 'mdi-check';
      setTimeout(() => this.copyIcon = 'mdi-content-copy', 2000);
    },
    input() {
      console.log(this.selectedColor)
      this.$emit('update:modelValue', this.selectedColor);
      this.$emit('change');
    }
  },
  watch: {
    selectedColor() {
      this.$emit('update:modelValue', this.selectedColor);
      this.$emit('change');
    }
  }
};
</script>
