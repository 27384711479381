<template>
  <BaseView>
    <template v-slot:main>
      Upload a file.
    </template>
  </BaseView>
</template>

<script>
export default {
  name: 'FileUploadView',
  data: () => ({

  })
}
</script>
