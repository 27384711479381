<template>
  <BaseView right-drawer-icon="mdi-palette" has-right-drawer>
    <template v-slot:main>
      <PaletteViewComponent
        style="height: 100%;"
        :colors="selected"
      ></PaletteViewComponent>
    </template>
    <template v-slot:app-bar-right>
    </template>
    <template v-slot:right-drawer>
      <v-card :elevation="0" :rounded="0" style="margin-bottom: 100px;" color="primary-darken-2">
        <!-- Provides the application the proper gutter -->
        <v-card
          v-for="type of types" :key="type"
          :style="{ backgroundColor: selected[type].hex, color: isDarkColor(selected[type].hex) ? 'white' : 'black' }"
          class="pa-4 rounded-0"
          background-color="none"
        >
          <v-card-title>{{ capitalize(type) }}</v-card-title>
          <v-card-text
            class="pa-4"
          >
            <ColorAutocompleteComponent
              :colors="colors"
              v-model="selected[type]"
              @change="colorUpdate"
            ></ColorAutocompleteComponent>
          </v-card-text>
        </v-card>
        <div class="pa-4 text-right">
          <v-btn
            icon
            @click="randomize"
            :dark="dark"
            title="Randomize Colours"
            :style="{ width: iconSize, height: iconSize, margin: '4px' }"
          >
            <v-icon>mdi-shuffle-variant</v-icon>
          </v-btn>
          <v-btn
            color="accent"
            @click="save"
            variant="flat"
            :disabled="!canSave"
          >Save</v-btn>
        </div>
      </v-card>
    </template>
  </BaseView>
</template>

<script setup>
import { isDarkColor } from '../lib/utils';
</script>

<script>
import { capitalize } from '../lib/utils';
import onecolor from 'onecolor';
import colors from '../colors.json';
import ColorAutocompleteComponent from '../components/ColorAutocompleteComponent.vue';
import PaletteViewComponent from '../components/PaletteViewComponent.vue';
import BaseView from './BaseView.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'HomeView',
  components: {
    ColorAutocompleteComponent,
    PaletteViewComponent,
    BaseView
  },
  created() {
    this.rightDrawer = this.$vuetify.display.lgAndUp
    this.colors.sort((a,b) => a.name > b.name ? 1 : -1);

    this.byFam = this.colors.reduce((acc, c) => {
      acc[c.fam] = acc[c.fam] || []
      acc[c.fam].push(c);
      return acc;
    }, {});

    const { base, white, accent } = this.$route.query;
    if (base && white && accent) {
      this.selected.base = this.colors.find(c => c.name == base);
      this.selected.white = this.colors.find(c => c.name == white);
      this.selected.accent = this.colors.find(c => c.name == accent);
    } else {
      if (this.savedPalettes?.length) {
        this.selected = this.savedPalettes[0];
      } else if (this.activePalette) {
        this.selected = JSON.parse(JSON.stringify(this.activePalette))
      } else {
        this.randomize();
      }
    }
    // console.log('selected', this.selected)
  },
  data: () => ({
    rightDrawer: false,
    types: [
      'base',
      'white',
      'accent',
    ],
    selected: {
      base: {
        hex: '',
        name: '',
      },
      white: {
        hex: '',
        name: '',
      },
      accent: {
        hex: '',
        name: '',
      },
    },
    lightThreshold: 0.5,
    savedPalettes: [],
    colorFamilies: [
      'red',
      'orange',
      'yellow',
      'green',
      'blue',
      'purple',
      'gray',
      'neutral',
      'white',
    ],
    colors,
  }),
  methods: {
    ...mapActions([
      'setHeaderColorHex',
      'setActivePalette',
    ]),
    capitalize,
    onecolor,
    randomize() {
      const newSelected = {};
      do {
        newSelected['base'] = this.colors[Math.floor(Math.random() * this.colors.length)];
      } while(onecolor(newSelected['base'].hex).lightness() > 0.5)
      do {
        newSelected['accent'] = this.byFam['red'][Math.floor(Math.random() * this.byFam['red'].length)];
      } while(onecolor(newSelected['accent'].hex).lightness() > 0.5)

      newSelected['white'] = this.byFam['white'][Math.floor(Math.random() * this.byFam['white'].length)];
      this.selected = newSelected;
    },
    save() {
      const palette = JSON.parse(JSON.stringify(this.selected));
      palette.key = this.getKey(palette);
      this.savedPalettes.push(palette);
      localStorage.setItem('sp', JSON.stringify(this.savedPalettes));
    },
    remove(item) {
      this.savedPalettes.splice(this.savedPalettes.indexOf(item), 1);
      localStorage.setItem('sp', JSON.stringify(this.savedPalettes));
    },
    getKey(palette) {
      return Object.entries(palette).map(([, v]) => v.name).join('/');
    },
    colorUpdate() {
      this.setActivePalette(this.selected);
    },
  },
  computed: {
    ...mapState([
      'headerColorHex',
      'activePalette',
    ]),
    canSave() {
      const keyToFind = this.getKey(this.selected);
      const found = this.savedPalettes.find(i => i.key == keyToFind)
      const canSave = found == null;
      return canSave;
    },
    headerColor() {
      const color = onecolor(this.selected['base'].hex || '#f00');
      return color.lightness(color.lightness() * 0.5);
    },
    dark() {
      return this.headerColor.lightness() < this.lightThreshold;
    },
    iconSize() {
      return ['xs', 'sm'].includes(this.$vuetify.display.name) ? '48px' : '56px'
    },
  },
  watch: {
    selected() {
      this.colorUpdate();
    }
  }
}
</script>
