<template>
  <v-card theme="light" :rounded="0">
    <v-toolbar color="primary-darken-2">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: '3pCard',
  props: {
    title: String
  }
}
</script>
