<template>
  <BaseView>
    <template v-slot:main>
      <v-container fluid color="primary-darken-2">
        <v-row>
          <v-col>
            <v-select
              label="Color Family"
              :items="families"
              v-model="selectedFamily"
              hide-details
              density="comfortable"
            ></v-select>
          </v-col>
          <v-col>
            <v-btn @click="sqSize--;" icon size="small" class="ma-2" color="accent">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn @click="sqSize++;" icon size="small" class="ma-2" color="accent">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <!-- <v-btn @click="sortBy('lightness')">By Lightness</v-btn>
            <v-btn @click="sortBy('saturation')">By Saturation</v-btn>
            <v-btn @click="sortByColor('red')">By Red</v-btn>
            <v-btn @click="sortByColor('blue')">By Blue</v-btn>
            <v-btn @click="sortByColor('green')">By Green</v-btn> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex flex-wrap justify-center">
              <v-hover v-for="c in byFam[selectedFamily]" :key="c.name">
                <template v-slot:default="{ isHovering, props }">
                  <div
                    v-bind="props"
                    class="color-square"
                    :class="(isHovering || selectedColor === c) ? 'color-square--selected' : ''"
                    :elevation="(isHovering || selectedColor === c) ? 20 : 0"
                    @click="selectColor(c)"
                    :style="{ 'z-index': (isHovering || selectedColor === c) ? 20 : 0, backgroundColor: c.hex, height: `${sqSize}vh`, width: `${sqSize}vh` }"
                  >
                  </div>
                </template>
              </v-hover>
            </div>
          </v-col>
          <v-col v-if="selectedColor" cols="4">
            <TPCard :title="`Color Details - ${selectedColor.name}`">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-list>
                      <v-list-item
                        :title="`Name: ${selectedColor.name}`"
                      ></v-list-item>
                      <v-list-item
                        :title="`Hex: ${selectedColor.hex}`"
                      ></v-list-item>
                      <v-list-item
                        :title="`Color Family: ${selectedColor.fam}`"
                      ></v-list-item>
                    </v-list>
                  </v-col>
                  <v-col>
                    <div :style="{ height: '100%', width: '100%', backgroundColor: selectedColor.hex }"></div>
                  </v-col>
                </v-row>
              </v-card-text>
            </TPCard>
            <TPCard title="Similar Colors" class="mt-4">
              <v-list>
                <v-list-item :value="c" :key="c.name" v-for="c in similarColors" @click="selectColor(c)">
                  <template v-slot:prepend>
                    <v-avatar :color="c.hex"></v-avatar>
                  </template>
                  <v-list-item-title v-text="c.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="c.hex"></v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </TPCard>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BaseView>
</template>

<style scoped>

.color-square {
  cursor: pointer;
}
.color-square--selected {
  border: 4px solid white;
}
</style>

<script setup>
import BaseView from './BaseView.vue';
import TPCard from '@/components/TPCard.vue';
import colors from '../colors.json';
import onecolor from 'onecolor';
</script>

<script>
export default {
  name: 'HomeView',
  created() {
    const byFam = this.colors.reduce((acc, c) => {
      acc[c.fam] = acc[c.fam] || []
      acc[c.fam].push(c);
      return acc;
    }, {});

    const savedSize = localStorage.getItem('sqSize');
    if (savedSize) {
      this.sqSize = JSON.parse(savedSize);
    }

    this.families = Object.keys(byFam).sort();

    this.byFam = byFam;
    this.sortBy('saturation');
    this.selectedFamily = this.families[0];
  },
  computed: {
    similarColors() {
      if (!this.selectedColor) {
        return [];
      }
      const osc = onecolor(this.selectedColor.hex);
      return this.colors.filter((c) => {
        const occ = onecolor(c.hex);
        const redDiff = Math.abs(occ.red() - osc.red());
        const blueDiff = Math.abs(occ.blue() - osc.blue());
        const greenDiff = Math.abs(occ.green() - osc.green());
        const totalDiff = redDiff + blueDiff + greenDiff;

        return totalDiff < 0.1 && c.name != this.selectedColor.name;
      })
    },
  },
  data: () => ({
    colors,
    byFam: {},
    families: [],
    selectedFamily: '',
    selectedColor: null,
    sqSize: 10,
  }),
  methods: {
    selectColor(color) {
      console.log(color)
      if (color == this.selectedColor) {
        this.selectedColor = null;
      } else {
        this.selectedColor = color;
        this.selectedFamily = color.fam;
      }
    },
    sortColorFamilies(sortFunction) {
      const bf = this.byFam;
      this.families.forEach(fam => this.byFam[fam].sort(sortFunction))
      this.byFam = null;
      this.byFam = bf;
    },
    sortBy(sortKey) {
      this.sortColorFamilies((a, b) => {
        return onecolor(b.hex)[sortKey]() - onecolor(a.hex)[sortKey]()
      })
    },
    sortByColor(color) {
      // const otherColors = ['red', 'green', 'blue'].filter(c => c != color);
      const colorMap = {
        red: '#f00',
        green: '#0F0',
        blue: '#00F',
        orange: '#F80',
        yellow: '#FF0',
        gray: '#444',
      };
      const ocbh = onecolor(colorMap[color]).hue();
      this.sortColorFamilies((a, b) => {
        const oca = onecolor(a.hex);
        const ocb = onecolor(b.hex);
        return (Math.abs(ocbh - oca.hue()) + 1 - oca.saturation()) - (Math.abs(ocbh - ocb.hue()) + 1 - oca.saturation())
        // return oca[color]() - otherColors.map(oc => oca[oc]()).reduce((partialSum, a) => partialSum + a, 0) > ocb[color]() - otherColors.map(oc => ocb[oc]()).reduce((partialSum, a) => partialSum + a, 0)
      })
    }
  },
  watch: {
    sqSize() {
      localStorage.setItem('sqSize', this.sqSize);
    },
    selectedFamily() {
      console.log(this.selectedFamily)
    }
  }
}
</script>
