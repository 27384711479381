// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const theme = {
  defaultTheme: 'myTheme',
  themes: {
    myTheme: {
      light: true,
      colors: {
        "background": "#121212",
        "surface": "#212121",
        "surface-variant": "#BDBDBD",
        "on-surface-variant": "#424242",
        "primary": "#BB86FC",
        "primary-darken-1": "#3700B3",
        "secondary": "#03DAC5",
        "secondary-darken-1": "#03DAC5",
        "error": "#CF6679",
        "info": "#2196F3",
        "success": "#4CAF50",
        "warning": "#FB8C00"
      },
    }
  }
};

const vuetify = createVuetify({
  components,
  directives,
  theme,
})

export default vuetify
