<template>
  <BaseView>
    <template v-slot:main>
        <v-stepper
          v-model="stepper"
          vertical
        >
          <v-stepper-step
            :complete="stepper > 1"
            step="1"
          >
            Select a base color
            <!-- <small>Summarize if needed</small> -->
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-chip-group
              active-class="accent white--text"
              v-model="selectedColorFamily"
              @change="colorPage = 1"
            >
              <v-chip
                class="primary darken-1 white--text"
                v-for="fam in colorFamilies"
                :key="fam"
                :value="fam"
              >
                {{ fam }}
              </v-chip>
            </v-chip-group>
            <!-- <v-select
              label="Color Family"
              v-model="selectedColorFamily"
              :items="colorFamilies"
            ></v-select> -->

            <v-pagination
              v-model="colorPage"
              :length="colorPageLength"
              class="primary darken-1"
              active-class="accent white--text"
            ></v-pagination>

            <v-sheet
              color="primary"
              class="justify-center mb-12"
            >
              <v-sheet class="d-flex flex-wrap">
                <v-hover
                  v-slot="{ hover }"
                  v-for="color in selectedColorPage"
                  :key="color.hex"
                >
                  <div
                    class="color-box"
                    :class="{ 'on-hover': hover }"
                    :style="{ backgroundColor: color.hex }"
                  ></div>
                </v-hover>
              </v-sheet>

              <!-- <v-color-picker
                class="ma-2"
                hide-canvas
                hide-sliders
                hide-inputs
                show-swatches
                :swatches="swatches"
                swatches-max-height="400px"
              ></v-color-picker> -->
            </v-sheet>


            <v-btn
              color="accent"
              @click="stepper = 2"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="stepper > 2"
            step="2"
          >
            Select a white
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card
              color="primary lighten-1"
              class="mb-12"
              height="200px"
            ></v-card>
            <v-btn
              color="accent"
              @click="stepper = 3"
            >
              Continue
            </v-btn>
            <v-btn @click="stepper = 1" text>
              Back
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="stepper > 3"
            step="3"
          >
            Select an accent colour
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card
              color="primary lighten-1"
              class="mb-12"
              height="200px"
            ></v-card>
            <v-btn
              color="accent"
              @click="stepper = 4"
            >
              Continue
            </v-btn>
            <v-btn text @click="stepper = 2">
              Back
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="4">
            Review
          </v-stepper-step>
          <v-stepper-content step="4">
            <v-card
              color="primary lighten-1"
              class="mb-12"
              height="200px"
            ></v-card>
            <v-btn
              color="accent"
              @click="stepper = 1"
            >
              Save
            </v-btn>
            <v-btn text @click="stepper = 3">
              Back
            </v-btn>
          </v-stepper-content>
        </v-stepper>
    </template>
  </BaseView>
</template>

<script>
import BaseView from './BaseView.vue';
import colors from '../colors.json';

export default {
  name: 'BuilderView',
  created() {
    this.byFamily = colors.reduce((acc, item) => {
      acc[item.fam] = acc[item.fam] || [];
      acc[item.fam].push(item);

      return acc;
    }, {});
    this.colorFamilies = Object.keys(this.byFamily);
  },
  data: () => ({
    byFamily: {},
    colorPage: 1,
    colorFamilies: [],
    colorsPerPage: 50,
    selectedColorFamily: 'red',
    stepper: 1,
  }),
  computed: {
    colorPageLength() {
      console.log(Math.max(Math.ceil(this.selectedColors.length / this.colorsPerPage), 1))
      return Math.max(Math.ceil(this.selectedColors.length / this.colorsPerPage), 1);
    },
    selectedColors() {
      return this.byFamily[this.selectedColorFamily] || [];
    },
    selectedColorPage() {
      const start = this.colorsPerPage * (this.colorPage - 1)
      const end = Math.min(start + 50, this.selectedColors.length);

      return this.selectedColors.slice(start, end)
    }
  },
  components: { BaseView }
};
</script>

<style>
.color-box {
  height: 10vh;
  width: 10%;
}

.color-box.on-hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  z-index: 50;
}
</style>
