import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
// import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import LoginView from '../views/LoginView.vue'
// import LogoutView from '../views/LogoutView.vue'
import ProfileView from '../views/ProfileView.vue'
// import { auth, logout, login } from '../auth'
import ErrorComponent from '../components/ErrorComponent'
import ComposerView from '../views/ComposerView'
import FileUploadView from '../views/FileUploadView.vue'
import ColorExplorerView from '../views/ColorExplorerView.vue';



const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'viewer',
      path: '/',
      component: HomeView,
      meta: { icon: 'mdi-palette', display: true, title: 'Palette Viewer' }
    },
    {
      name: 'explorer',
      path: '/explorer',
      component: ColorExplorerView,
      meta: { icon: 'mdi-palette', display: true, title: 'Color Explorer' },
    },
    {
      name: 'composer',
      path: '/composer',
      component: ComposerView,
      meta: { icon: 'mdi-palette', display: false, title: 'Palette Composer' },
    },
    // {
    //   name: 'login',
    //   path: '/login',
    //   component: LoginView,
    //   beforeEnter() {
    //     login();
    //   }
    // },
    // {
    //   path: '/login/oauth2/code/cognito',
    //   beforeEnter() {
    //     auth.parseCognitoWebResponse(window.location.href);
    //   }
    // },
    // {
    //   name: 'logout',
    //   path: '/logout',
    //   beforeEnter(to, from, next) {
    //     logout();
    //     next()
    //   },
    //   component: LogoutView,
    // },
    {
      path: '/error',
      component: ErrorComponent
    },
    {
      name: 'profile',
      path: '/profile',
      component: ProfileView,
    },
    {
      name: 'upload',
      path: '/upload',
      component: FileUploadView,
    }
  ],
});

const DEFAULT_TITLE = '3Palette.com';

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
      document.title = to.meta.title ? `${to.meta.title} - 3Palette` : DEFAULT_TITLE;
  });
});

export default router
