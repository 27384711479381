<template>
  <router-view></router-view>
</template>

<style>
html, body {
  overflow: hidden;
}
.sp > div {
  height: 2em;
  width: 6em;
}

.app-name {
  font-family: 'Pacifico', cursive;
  font-size: 1.5em;
}
</style>

<script>
export default {
  name: 'App',

  created(){
  },

  data: () => ({})
};
</script>
