<template>
  <v-app>
    <v-app-bar app color="primary-darken-2" :elevation="0">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        :style="{ width: iconSize, height: iconSize, margin: '4px' }"
      ></v-app-bar-nav-icon>
      <slot name="app-bar-left"></slot>
      <v-spacer></v-spacer>
      <span class="app-name">3Palette</span>
      <v-spacer></v-spacer>
      <slot name="app-bar-right"></slot>

      <v-btn
        icon
        v-if="hasRightDrawer"
        @click="rightDrawer = !rightDrawer"
        :style="{ width: iconSize, height: iconSize, margin: '4px' }"
      >
        <v-icon>{{ rightDrawerIcon || 'mdi-menu' }}</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      app
      v-model="drawer"
      color="primary-darken-2"
    >
      <!-- <div class="text-left">
        <v-btn
          style="margin: 4px;"
          :style="{ width: iconSize, height: iconSize }"
          @click="drawer = !drawer"
          color="primary"
          icon
        >
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </div> -->
      <slot name="left-drawer">
        <template v-if="loggedIn && userdata">
          <v-list>
            <v-list-item class="justify-center">
              <v-avatar :image="userdata.picture"></v-avatar>
            </v-list-item>
            <v-list-item link class="text-center">
                <v-list-item-title class="text-h6">
                  {{ userdata.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ userdata.email }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item
              class="text-center"
            >
              <v-btn
                @click="logout"
                size="small"
                block
              >Log out</v-btn>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
        </template>
        <v-list nav dense>
          <v-list-item
            class="justify-center pa-0"
            v-if="!loggedIn"
          >
            <GoogleLogin :callback="handleLogin"/>
            <!-- <v-btn
              :to="{ name: 'login' }"
              color="accent"
              :loading="loadingLogin"
            >Log in</v-btn> -->
          </v-list-item>
          <v-list-item
            :to="route"
            v-for="route in routes"
            :key="route.path"
            :exact="true"
            :prepend-icon="route.meta.icon"
          >
            {{ capitalize(route.name) }}
          </v-list-item>
        </v-list>
      </slot>
    </v-navigation-drawer>
    <v-navigation-drawer
      app
      width="500"
      v-model="rightDrawer"
      color="primary-darken-2"
      location="right"
      v-if="hasRightDrawer"
    >
      <slot name="right-drawer"></slot>
    </v-navigation-drawer>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- <v-container fluid class="pa-0" style="overflow-y: auto; height: calc(100vh - 80px);"> -->
      <v-container fluid class="pa-0" style="overflow-y: auto; height: calc(100vh - 80px);">
        <slot name="main"></slot>
      </v-container>
    </v-main>


    <v-footer app color="primary-darken-2">
      <slot name="footer"></slot>
      <!-- <notifications position="bottom center"/> -->
    </v-footer>
  </v-app>
</template>

<script setup>
import { decodeCredential } from 'vue3-google-login';
import store from '../store';
const handleLogin = (response) => {
  // decodeCredential will retrive the JWT payload from the credential
  const userData = decodeCredential(response.credential)
  store.dispatch('login', userData);
  console.log("Handle the userData", userData)
}
</script>

<script>
import { mapState, mapActions } from 'vuex';
import onecolor from 'onecolor';
import { capitalize } from '../lib/utils';

export default {
  props: {
    hasRightDrawer: Boolean,
    rightDrawerIcon: String,
  },
  created() {

    this.drawer = this.$vuetify.display.lgAndUp;
  },
  computed: {
    routes() {
      // console.log(this.$route)
      return this.$router.getRoutes().filter(r => r.meta.display)
    },
    ...mapState([
      'headerColorHex',
      'lightThreshold',
      'loggedIn',
      'cognitoInfo',
      'loadingLogin',
      'errorloadingLogin',
      'activePalette',
      'userdata',
      'setActivePalette',
    ]),
    dark() {
      return onecolor(this.headerColorHex || 'f00').lightness() < this.lightThreshold;
    },
    iconSize() {
      return ['xs', 'sm'].includes(this.$vuetify.display.name) ? '48px' : '56px'
    },
    iconMargin() {
      return ['xs', 'sm'].includes(this.$vuetify.display.name) ? '8px' : '4px'
    },
  },
  data: () => ({
    drawer: false,
    rightDrawer: false,
    selectedItem: null,
  }),
  methods: {
    ...mapActions(['logout']),
    onecolor,
    capitalize
  }
}
</script>
