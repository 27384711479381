<template>
  <BaseView>
    <template v-slot:main>
      <v-container class="fill-height justify-center">
        <v-card color="primary darken-2" dark width="600px">
          <v-card-media src="src" height="200px">
          </v-card-media>
          <v-card-title>
            Profile
          </v-card-title>
          <v-card-text>
            <form action="">
              <v-text-field
                label="Some sort of text input"
              ></v-text-field>
            </form>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="accent">save</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </template>
  </BaseView>
</template>

<script>
import BaseView from './BaseView.vue';

export default {
  name: 'LoginView',
  components: { BaseView },
  data: () => ({
    register: false,
  })
};
</script>
