import onecolor from 'onecolor';

export function capitalize(sentence) {
  let newWords = [];
  for(let word of sentence.split(" ")) {
    newWords.push(word.charAt(0).toUpperCase() + word.slice(1))
  }

  return newWords.join(" ");
}

export function isDarkColor(color) {
  if (!color) {
    console.error('Undefined color passed to isDarkColor');
    return
  }
  return onecolor(color).lightness() < 0.5
}
