import { createApp, h } from 'vue'
import store from './store';
import vuetify from './plugins/vuetify'
import App from './App.vue'
// import Notifications from 'vue-notification'
import router from './router'
// import auth from './plugins/auth'

import vue3GoogleLogin from 'vue3-google-login'

const app = createApp({
  router,
  store,
  render: () => h(App)
})
// app.use(Notifications)
app.use(router)
app.use(vuetify)
app.use(store)

app.use(vue3GoogleLogin, {
  clientId: '604914405453-1ot12mgamc21nbj6r994go3ko5jrghj6.apps.googleusercontent.com'
})

app.mount('#app')


// Vue.use(auth)
